// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
//  URL_SERVICE: 'http://190.85.106.122:8888/WSRestApiCoopedac/APIRest/', //Pruebas [IP Pública del servidor 35]
  // URL_SERVICE: 'http://localhost:8081/WSRestApiCoopedac/APIRest/', //Desarrollo
  // URL_SERVICE: 'http://10.10.30.35:8888/WSRestApiCoopedac/APIRest/',     //pruebas Oficina
  URL_SERVICE: 'https://coopedacbe.ebscreditodigital.com//WSRestApiCoopedac/APIRest/',     //produccion
  // URL_SERVICE: 'http://190.85.106.122:8888/WSRestApiCoopedac-v2/APIRest/', //Pruebas [IP Pública del servidor 35]
};

// ng build --output-hashing=all --base-href /CoopedacTest/
// ng build --output-hashing=all --base-href /CoopedacTesting/
// ng build --output-hashing=all --base-href /Coopedac/
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
