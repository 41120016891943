import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'cliente/login',
    pathMatch: 'full'
  },
  {
    path:'cliente',
    loadChildren: () => import('./aplicativo/cliente/cliente.module').then( m => m.ClienteModule)
  },
  {
    path:'administrador',
    loadChildren: () => import('./aplicativo/administrador/administrador.module').then( m => m.AdministradorModule)
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'cliente/login'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { 
  constructor () {
    
  }
}
